//SKIP
/*####
  Open Sans Condensed
####*/
/* latin-ext */
@font-face {
    font-family: "Open Sans Condensed";
    font-style: normal;
    font-weight: 300;
    src: local("?"),
        url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xD_0YkshJ_UyXEU-Q7eV4G5_v5H-gcmDugi2HQeB0BPm.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Open Sans Condensed";
    font-style: normal;
    font-weight: 300;
    src: local("?"),
        url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xMmDra0ONnO3FPH--kzkC5zr7w4p9aSvGirXi6XmeXNA.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: "Open Sans Condensed";
    font-style: normal;
    font-weight: 700;
    src: local("?"),
        url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xPJmhJzg6kSQTtrFMk-8lT5_v5H-gcmDugi2HQeB0BPm.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Open Sans Condensed";
    font-style: normal;
    font-weight: 700;
    src: local("?"),
        url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xIgEy7irt_A5K-aDq9kG7DHr7w4p9aSvGirXi6XmeXNA.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/*####
    Open Sans
  ####*/
/* latin-ext */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local("?"),
        url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xD_0YkshJ_UyXEU-Q7eV4G5_v5H-gcmDugi2HQeB0BPm.woff2)
            format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local("?"),
        url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xMmDra0ONnO3FPH--kzkC5zr7w4p9aSvGirXi6XmeXNA.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Open Sans Regular"), local("OpenSans-Regular"),
        url(https://fonts.gstatic.com/s/opensans/v14/u-WUoqrET9fUeobQW7jkRYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Open Sans Regular"), local("OpenSans-Regular"),
        url(https://fonts.gstatic.com/s/opensans/v14/cJZKeOuBrn4kERxqtaUH3ZBw1xU1rKptJj_0jans920.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* latin-ext */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"),
        url(https://fonts.gstatic.com/s/opensans/v14/k3k702ZOKiLJc3WVjuplzIjoYw3YTyktCCer_ilOlhE.woff2) format("woff2");
    unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"),
        url(https://fonts.gstatic.com/s/opensans/v14/k3k702ZOKiLJc3WVjuplzBampu5_7CjHW5spxoeN3Vs.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* Fuentes fuera del template */

@font-face {
    font-family: "Simplon BP Mono";
    src: url("/assets/fonts/SimplonBPMono-Bold.eot");
    src: url("/assets/fonts/SimplonBPMono-Bold.woff2") format("woff2"),
        url("/assets/fonts/SimplonBPMono-Bold.woff") format("woff"),
        url("/assets/fonts/SimplonBPMono-Bold.ttf") format("truetype"),
        url("/assets/fonts/SimplonBPMono-Bold.svg#SimplonBPMono-Bold") format("svg"),
        url("/assets/fonts/SimplonBPMono-Bold.eot?#iefix") format("embedded-opentype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Simplon BP Mono";
    src: url("/assets/fonts/SimplonBPMono-Light.eot");
    src: url("/assets/fonts/SimplonBPMono-Light.woff2") format("woff2"),
        url("/assets/fonts/SimplonBPMono-Light.woff") format("woff"),
        url("/assets/fonts/SimplonBPMono-Light.ttf") format("truetype"),
        url("/assets/fonts/SimplonBPMono-Light.svg#SimplonBPMono-Light") format("svg"),
        url("/assets/fonts/SimplonBPMono-Light.eot?#iefix") format("embedded-opentype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Simplon BP Mono";
    src: url("/assets/fonts/SimplonBPMono-Medium.eot");
    src: url("/assets/fonts/SimplonBPMono-Medium.woff2") format("woff2"),
        url("/assets/fonts/SimplonBPMono-Medium.woff") format("woff"),
        url("/assets/fonts/SimplonBPMono-Medium.ttf") format("truetype"),
        url("/assets/fonts/SimplonBPMono-Medium.svg#SimplonBPMono-Medium") format("svg"),
        url("/assets/fonts/SimplonBPMono-Medium.eot?#iefix") format("embedded-opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Simplon BP Mono";
    src: url("/assets/fonts/SimplonBPMono-Regular.eot");
    src: url("/assets/fonts/SimplonBPMono-Regular.woff2") format("woff2"),
        url("/assets/fonts/SimplonBPMono-Regular.woff") format("woff"),
        url("/assets/fonts/SimplonBPMono-Regular.ttf") format("truetype"),
        url("/assets/fonts/SimplonBPMono-Regular.svg#SimplonBPMono-Regular") format("svg"),
        url("/assets/fonts/SimplonBPMono-Regular.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Simplon Mono";
    src: url("/assets/fonts/SimplonMono-LightItalic.eot");
    src: url("/assets/fonts/SimplonMono-LightItalic.woff2") format("woff2"),
        url("/assets/fonts/SimplonMono-LightItalic.woff") format("woff"),
        url("/assets/fonts/SimplonMono-LightItalic.ttf") format("truetype"),
        url("/assets/fonts/SimplonMono-LightItalic.svg#SimplonMono-LightItalic") format("svg"),
        url("/assets/fonts/SimplonMono-LightItalic.eot?#iefix") format("embedded-opentype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Simplon Mono";
    src: url("/assets/fonts/SimplonMono-MediumItalic.eot");
    src: url("/assets/fonts/SimplonMono-MediumItalic.woff2") format("woff2"),
        url("/assets/fonts/SimplonMono-MediumItalic.woff") format("woff"),
        url("/assets/fonts/SimplonMono-MediumItalic.ttf") format("truetype"),
        url("/assets/fonts/SimplonMono-MediumItalic.svg#SimplonMono-MediumItalic") format("svg"),
        url("/assets/fonts/SimplonMono-MediumItalic.eot?#iefix") format("embedded-opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Simplon Mono";
    src: url("/assets/fonts/SimplonMono-Italic.eot");
    src: url("/assets/fonts/SimplonMono-Italic.woff2") format("woff2"),
        url("/assets/fonts/SimplonMono-Italic.woff") format("woff"),
        url("/assets/fonts/SimplonMono-Italic.ttf") format("truetype"),
        url("/assets/fonts/SimplonMono-Italic.svg#SimplonMono-Italic") format("svg"),
        url("/assets/fonts/SimplonMono-Italic.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: italic;
}

//ENDSKIP
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
