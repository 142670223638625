@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/*####
  Open Sans Condensed
####*/
/* latin-ext */
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import "~angular-calendar/css/angular-calendar.css";
@import url("https://s3-us-west-1.amazonaws.com/ogr-icons-font/style.ogr_fonts.css");
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  src: local("?"), url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xD_0YkshJ_UyXEU-Q7eV4G5_v5H-gcmDugi2HQeB0BPm.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  src: local("?"), url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xMmDra0ONnO3FPH--kzkC5zr7w4p9aSvGirXi6XmeXNA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  src: local("?"), url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xPJmhJzg6kSQTtrFMk-8lT5_v5H-gcmDugi2HQeB0BPm.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 700;
  src: local("?"), url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xIgEy7irt_A5K-aDq9kG7DHr7w4p9aSvGirXi6XmeXNA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/*####
    Open Sans
  ####*/
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("?"), url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xD_0YkshJ_UyXEU-Q7eV4G5_v5H-gcmDugi2HQeB0BPm.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("?"), url(https://fonts.gstatic.com/s/opensanscondensed/v10/gk5FxslNkTTHtojXrkp-xMmDra0ONnO3FPH--kzkC5zr7w4p9aSvGirXi6XmeXNA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v14/u-WUoqrET9fUeobQW7jkRYX0hVgzZQUfRDuZrPvH3D8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://fonts.gstatic.com/s/opensans/v14/cJZKeOuBrn4kERxqtaUH3ZBw1xU1rKptJj_0jans920.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v14/k3k702ZOKiLJc3WVjuplzIjoYw3YTyktCCer_ilOlhE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v14/k3k702ZOKiLJc3WVjuplzBampu5_7CjHW5spxoeN3Vs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
/* Fuentes fuera del template */
@font-face {
  font-family: "Simplon BP Mono";
  src: url("/assets/fonts/SimplonBPMono-Bold.eot");
  src: url("/assets/fonts/SimplonBPMono-Bold.woff2") format("woff2"), url("/assets/fonts/SimplonBPMono-Bold.woff") format("woff"), url("/assets/fonts/SimplonBPMono-Bold.ttf") format("truetype"), url("/assets/fonts/SimplonBPMono-Bold.svg#SimplonBPMono-Bold") format("svg"), url("/assets/fonts/SimplonBPMono-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Simplon BP Mono";
  src: url("/assets/fonts/SimplonBPMono-Light.eot");
  src: url("/assets/fonts/SimplonBPMono-Light.woff2") format("woff2"), url("/assets/fonts/SimplonBPMono-Light.woff") format("woff"), url("/assets/fonts/SimplonBPMono-Light.ttf") format("truetype"), url("/assets/fonts/SimplonBPMono-Light.svg#SimplonBPMono-Light") format("svg"), url("/assets/fonts/SimplonBPMono-Light.eot?#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Simplon BP Mono";
  src: url("/assets/fonts/SimplonBPMono-Medium.eot");
  src: url("/assets/fonts/SimplonBPMono-Medium.woff2") format("woff2"), url("/assets/fonts/SimplonBPMono-Medium.woff") format("woff"), url("/assets/fonts/SimplonBPMono-Medium.ttf") format("truetype"), url("/assets/fonts/SimplonBPMono-Medium.svg#SimplonBPMono-Medium") format("svg"), url("/assets/fonts/SimplonBPMono-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Simplon BP Mono";
  src: url("/assets/fonts/SimplonBPMono-Regular.eot");
  src: url("/assets/fonts/SimplonBPMono-Regular.woff2") format("woff2"), url("/assets/fonts/SimplonBPMono-Regular.woff") format("woff"), url("/assets/fonts/SimplonBPMono-Regular.ttf") format("truetype"), url("/assets/fonts/SimplonBPMono-Regular.svg#SimplonBPMono-Regular") format("svg"), url("/assets/fonts/SimplonBPMono-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Simplon Mono";
  src: url("/assets/fonts/SimplonMono-LightItalic.eot");
  src: url("/assets/fonts/SimplonMono-LightItalic.woff2") format("woff2"), url("/assets/fonts/SimplonMono-LightItalic.woff") format("woff"), url("/assets/fonts/SimplonMono-LightItalic.ttf") format("truetype"), url("/assets/fonts/SimplonMono-LightItalic.svg#SimplonMono-LightItalic") format("svg"), url("/assets/fonts/SimplonMono-LightItalic.eot?#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Simplon Mono";
  src: url("/assets/fonts/SimplonMono-MediumItalic.eot");
  src: url("/assets/fonts/SimplonMono-MediumItalic.woff2") format("woff2"), url("/assets/fonts/SimplonMono-MediumItalic.woff") format("woff"), url("/assets/fonts/SimplonMono-MediumItalic.ttf") format("truetype"), url("/assets/fonts/SimplonMono-MediumItalic.svg#SimplonMono-MediumItalic") format("svg"), url("/assets/fonts/SimplonMono-MediumItalic.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Simplon Mono";
  src: url("/assets/fonts/SimplonMono-Italic.eot");
  src: url("/assets/fonts/SimplonMono-Italic.woff2") format("woff2"), url("/assets/fonts/SimplonMono-Italic.woff") format("woff"), url("/assets/fonts/SimplonMono-Italic.ttf") format("truetype"), url("/assets/fonts/SimplonMono-Italic.svg#SimplonMono-Italic") format("svg"), url("/assets/fonts/SimplonMono-Italic.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: italic;
}
body.body-default {
  background-color: #ececec;
  background-image: url(/assets/img/pattern.png);
  background-size: 180px auto;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

/* Profile 1: docentes */
body.profile1,
.body_profile1 {
  background-color: #ececec;
  background-image: url(/assets/img/pattern.png);
  background-size: 180px auto;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

/* Profile 2: estudiantes */
body.profile2 {
  background-color: #ececec;
  background-image: url(/assets/img/pattern.png);
  background-size: 180px auto;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

body.profile3 {
  background-color: #ececec;
  background-image: url(/assets/img/pattern.png);
  background-size: 180px auto;
  background-repeat: repeat;
  font-family: var(--custom-font-family2), Sans-Serif;
}

.btn_base_1 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background-color: var(--base-primary_button_background_color);
  border: none;
  text-transform: uppercase;
  color: var(--base-primary_button_font_color);
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_1:hover {
  background-color: var(--base-primary_button_background_color_hover);
  color: var(--base-primary_button_font_color_hover);
  text-decoration: underline;
}
.btn_base_1:focus, .btn_base_1:active {
  background: var(--base-primary_button_background_color);
  color: var(--base-primary_button_font_color_hover);
  outline: none;
  box-shadow: none;
}
.btn_base_1:active:focus {
  transform: scale(0.9);
  box-shadow: none;
}

.btn_base_2 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background-color: var(--base-cancel_button_background_color);
  color: var(--base-cancel_button_font_color);
  border: none;
  text-transform: uppercase;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_2:hover {
  background-color: var(--base-cancel_button_background_color_hover);
  color: var(--base-cancel_button_font_color_hover);
}
.btn_base_2:focus, .btn_base_2:active {
  background-color: var(--base-cancel_button_background_color);
  color: var(--base-cancel_button_font_color);
  outline: none;
}
.btn_base_2:active:focus {
  transform: scale(0.9);
}

.btn_base_3 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background-color: var(--base-secondary_button_background_color);
  border: none;
  color: var(--base-secondary_button_font_color);
  font-weight: 300;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_3:hover {
  background-color: var(--base-secondary_button_background_color_hover);
  color: var(--base-secondary_button_font_color_hover);
  text-decoration: underline;
}
.btn_base_3:focus, .btn_base_3:active {
  background-color: var(--base-secondary_button_background_color_hover);
  color: var(--base-secondary_button_font_color_hover);
  outline: none;
  box-shadow: none;
}
.btn_base_3:active:focus {
  transform: scale(0.9);
  box-shadow: none;
}

.btn_base_4 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid #353535;
  color: #353535;
  font-weight: 300;
  font-family: var(--custom-font-family1), Sans-Serif;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.btn_base_4:hover {
  color: #353535;
  text-decoration: underline;
}
.btn_base_4:focus, .btn_base_4:active, .btn_base_4.active {
  background: #353535;
  border: 1px solid #353535;
  color: white;
  outline: none;
  box-shadow: none;
}
.btn_base_4:active:focus {
  transform: scale(0.9);
  box-shadow: none;
}

.btn-table {
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background: #2691c5;
  color: white;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  border: none;
  padding: 6px;
  width: 26px;
  height: 26px;
  line-height: 14px;
  font-size: 14px;
}
.btn-table:hover {
  background: #1e729a;
}
.btn-table:focus {
  color: white;
}

.small-round-btn {
  background-color: #336699;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  display: block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.small-round-btn:hover {
  opacity: 0.8;
}
.small-round-btn .small-round-btn-inner {
  display: table-row;
}
.small-round-btn a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.custom_title_1,
.custom_title {
  padding: 12px 20px 18px 20px;
  color: #4e5457;
  background: url(/public/img/h2.png) no-repeat;
  background-size: 100% 100%;
  max-width: 481px;
  height: 86px;
  text-align: center;
  margin: 30px auto 20px auto;
}
.custom_title_1 h2,
.custom_title h2 {
  margin: 0;
  font-weight: normal;
  line-height: 32px;
  text-align: center;
  font-family: var(--custom-font-family1);
  font-size: 32px;
}

.custom_title_2 {
  position: relative;
  z-index: 1;
  text-align: center;
}
.custom_title_2 .ribbon {
  font-weight: normal;
  font-size: 26px;
  display: inline-block;
  max-width: 90%;
  min-width: 30%;
  font-family: var(--custom-font-family1), Sans-Serif;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  position: relative;
  background: #8f7047;
  color: #fff;
  text-align: center;
  padding: 0.5em 1em; /* Adjust to suit */
  margin: 1em auto 1em;
}
.custom_title_2 .ribbon:before,
.custom_title_2 .ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -0.5em;
  border: 1em solid #b59069;
  z-index: -1;
}
.custom_title_2 .ribbon:before {
  left: -2em;
  border-right-width: 2em;
  border-left-color: transparent;
}
.custom_title_2 .ribbon:after {
  right: -2em;
  border-left-width: 2em;
  border-right-color: transparent;
}
.custom_title_2 .ribbon .ribbon-content:before,
.custom_title_2 .ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #96714b transparent transparent transparent;
  bottom: -0.5em;
}
.custom_title_2 .ribbon .ribbon-content:before {
  left: 0;
  border-width: 0.5em 0 0 1em;
}
.custom_title_2 .ribbon .ribbon-content:after {
  right: 0;
  border-width: 0.5em 1em 0 0;
}

.h3_sub_title_1 {
  margin: 0;
  padding: 0;
  font-size: var(--base-subtitle_size);
  color: var(--base-subtitle_color);
  width: 100%;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--base-subtitle_underline_color);
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_2 {
  margin: 0;
  padding: 0;
  font-size: var(--base-subtitle_size);
  color: var(--base-subtitle_color);
  width: 100%;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--base-subtitle_underline_color);
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_3 {
  margin: 0;
  padding: 0;
  font-size: var(--base-subtitle_size);
  color: var(--base-subtitle_color);
  width: 100%;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--base-subtitle_underline_color);
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h3_sub_title_9c3 {
  margin: 0;
  padding: 0;
  font-size: var(--base-subtitle_size);
  color: var(--base-subtitle_color);
  width: 100%;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--base-subtitle_underline_color);
  font-family: var(--custom-font-family1), Sans-Serif;
}

.h4_sub_title_1 {
  font-weight: 700;
  font-size: 14px;
}

table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas tbody > tr > th,
table.tabla_estadisticas thead > tr > th {
  padding: 4px 6px;
  vertical-align: middle;
}
table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas tbody > tr > th {
  border: none;
}
table.tabla_estadisticas tbody > tr > td,
table.tabla_estadisticas thead > tr > th {
  text-align: center;
}
table.tabla_estadisticas tbody > tr:nth-of-type(odd) {
  background-color: #e9f5d2;
}
table.tabla_estadisticas thead > tr > th {
  border-bottom: 2px solid #c0d696;
}
table.tabla_estadisticas thead > tr > th:first-child,
table.tabla_estadisticas tbody > tr > th:first-child {
  text-align: left;
}

table.tabla_ensayos thead > tr > th,
table.tabla_planes_personales thead > tr > th {
  padding: 8px 6px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
  background-color: #fff4d2;
  color: #663300;
}
table.tabla_ensayos thead > tr > th:first-child,
table.tabla_planes_personales thead > tr > th:first-child {
  border-left: none;
  border-radius: 6px 0 0 0;
}
table.tabla_ensayos thead > tr > th:last-child,
table.tabla_planes_personales thead > tr > th:last-child {
  border-right: none;
  border-radius: 0 6px 0 0;
}
table.tabla_ensayos thead > tr > th.actions,
table.tabla_planes_personales thead > tr > th.actions {
  width: 1%;
}
table.tabla_ensayos tbody > tr > td,
table.tabla_planes_personales tbody > tr > td {
  padding: 5px;
  font-size: 12px;
  text-align: left;
  vertical-align: middle;
  border: none;
}
table.tabla_ensayos tbody > tr > td.actions,
table.tabla_planes_personales tbody > tr > td.actions {
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
table.tabla_ensayos tbody > tr:nth-of-type(even),
table.tabla_planes_personales tbody > tr:nth-of-type(even) {
  background-color: #f1f1f1;
}
table.tabla_ensayos tbody > tr > td.min-size,
table.tabla_ensayos thead > tr > th.min-size,
table.tabla_planes_personales tbody > tr > td.min-size,
table.tabla_planes_personales thead > tr > th.min-size {
  width: 1%;
  text-align: center;
}
table.tabla_ensayos tbody > tr > th.acciones,
table.tabla_ensayos tbody > tr > td.acciones,
table.tabla_planes_personales tbody > tr > th.acciones,
table.tabla_planes_personales tbody > tr > td.acciones {
  width: 1%;
  text-align: center;
}
table.tabla_ensayos tbody > tr > td.correcta,
table.tabla_planes_personales tbody > tr > td.correcta {
  background-color: #d5eca9;
}
table.tabla_ensayos.color-scheme-1 thead > tr > th,
table.tabla_planes_personales.color-scheme-1 thead > tr > th {
  background-color: #fff4d2;
  color: #663300;
}
table.tabla_ensayos.color-scheme-2 thead > tr > th,
table.tabla_planes_personales.color-scheme-2 thead > tr > th {
  background-color: #d6d6d6;
  color: #5f5f5f;
}

.carousel_style_1 {
  padding-bottom: 30px;
}
.carousel_style_1 .sp-controllers {
  bottom: 0px;
}
@media screen and (min-width: 700px) {
  .carousel_style_1 .sp-arrow-left {
    left: 50%;
    margin-left: -260px;
  }
  .carousel_style_1 .sp-arrow-right {
    right: 50%;
    margin-right: -260px;
  }
}
.carousel_style_1 .circle {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: none;
  background-color: #ffcc33;
  color: #663300;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.carousel_style_1 .circle:hover {
  background-color: #ffe38e;
}
.carousel_style_1 .circle span.circle-number {
  font-size: 16px;
  display: block;
}
.carousel_style_1 .selected-circle {
  background-color: #ffe38e;
}
.carousel_style_1 .sp-slider > li h4 {
  display: inline-block;
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 24px;
  margin-top: 10px;
  padding: 3px 5px;
  margin-bottom: 10px;
  color: #333;
  max-width: 440px;
  background: rgba(255, 255, 255, 0.4);
}
@media screen and (max-width: 500px) {
  .carousel_style_1 .sp-slider > li h4 {
    font-size: 18px;
  }
}
.carousel_style_1 .sp-slider > li img {
  padding: 10px 0 0 0;
}

.cui-showcasebox-theme-default .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-default .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.cui-showcasebox-theme-primary .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-primary .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.cui-showcasebox-theme-noticias .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-noticias .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.cui-showcasebox-theme-establecimientos .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1 .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.showcasebox_style_1 .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.showcasebox_style_1 .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1 .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_carreras .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_carreras .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_primary .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.showcasebox_style_1_primary .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.showcasebox_style_1_primary .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_primary .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_establecimientos .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_blog .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.showcasebox_style_1_blog .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.showcasebox_style_1_blog .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_blog .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_1_ranking .showcasebox .showcasebox_title {
  padding: 10px 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: var(--base-showcasebox_title_background_color);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon img {
  height: 40px;
  width: auto;
  margin-left: 5px;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_icon .icon-container .icon {
  font-size: 30px;
  color: var(--base-showcasebox_icon_color);
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_title .showcasebox_title_inner .showcasebox_title_text {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 18px;
  color: var(--base-showcasebox_title_color);
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_content {
  overflow: hidden;
  padding: 12px 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.showcasebox_style_1_ranking .showcasebox .showcasebox_content .info_text {
  color: #1a1a1a;
  margin-bottom: 0;
}

.showcasebox_style_2 .showcasebox {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
}

.showcasebox_style_user .btn_style {
  font-size: 12px;
}
.showcasebox_style_user .left_column {
  float: left;
  padding-right: 5px;
}
.showcasebox_style_user .right_column {
  margin-left: 85px;
  padding-left: 5px;
}
.showcasebox_style_user .user-circle-container {
  background-color: #5fb9e6;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
  min-height: auto;
  height: 80px;
  width: 80px;
}
.showcasebox_style_user .user-circle-container .fa-placeholder .fa {
  font-size: 80px;
  padding-top: 6px;
}
.showcasebox_style_user .user-circle-container img.avatar {
  width: 100%;
  height: 100%;
}
.showcasebox_style_user h4 {
  font-family: var(--custom-font-family1), Sans-Serif;
  font-size: 20px;
  border-bottom: 2px solid #2691c5;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: #5b5b5f;
}
.showcasebox_style_user .email {
  margin-bottom: 15px;
  color: #5b5b5f;
}
.showcasebox_style_user .email fa {
  color: #5b5b5f;
  font-size: 110%;
  opacity: 0.4;
}
.showcasebox_style_user .profile-link {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 13px;
}
.showcasebox_style_user .profile-link:hover {
  text-decoration: none;
}
.showcasebox_style_user .edit-link {
  display: inline-block;
  font-size: 13px;
}
.showcasebox_style_user .edit-link:hover {
  text-decoration: none;
}
.showcasebox_style_user .no-email {
  margin-bottom: 15px;
  color: #848484;
  font-size: 90%;
}
.showcasebox_style_user .no-email fa {
  color: #f7cb43;
  font-size: 110%;
}
.showcasebox_style_user .clear-btn {
  margin-bottom: 5px;
}

body .modal-content {
  border: none;
}
body .modal-content .modal-header {
  border-bottom: none;
}
body .modal-content .modal-header .close {
  font-size: 34px;
  margin-top: -4px;
}
body .modal-content .modal-header .modal-title {
  color: #000000;
  font-family: var(--custom-font-family1), Sans-Serif;
}

.menu_item_box {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
  padding: 0;
  display: block;
  color: #333;
}
@media screen and (max-width: 500px) {
  .menu_item_box {
    margin-bottom: 20px;
  }
}
.menu_item_box:hover {
  text-decoration: none;
}
.menu_item_box:focus {
  outline: none;
  text-decoration: none;
}
.menu_item_box:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.menu_item_box .menu_item_row_wrapper {
  display: flex;
}
.menu_item_box .menu_item_box_icon_container {
  width: 100px;
  padding-right: 10px;
}
@media screen and (max-width: 500px) {
  .menu_item_box .menu_item_box_icon_container {
    width: 70px;
  }
}
.menu_item_box .menu_item_box_icon_container .menu_item_box_icon {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--base-menu_item_background_left_color);
  overflow: hidden;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu_item_box .menu_item_box_icon_container .menu_item_box_icon .icono {
  font-size: 58px;
  color: var(--base-menu_item_background_left_font_color);
}
@media screen and (max-width: 500px) {
  .menu_item_box .menu_item_box_icon_container .menu_item_box_icon {
    width: 60px;
  }
  .menu_item_box .menu_item_box_icon_container .menu_item_box_icon .icono {
    font-size: 40px;
  }
}
.menu_item_box .menu_item_box_body {
  display: table-cell;
  vertical-align: top;
  padding: 10px;
  padding-bottom: 50px;
  position: relative;
}
.menu_item_box .menu_item_box_body .menu_item_box_title {
  margin: 0 0 10px;
  margin: 0;
  padding: 0;
  font-size: var(--base-subtitle_size);
  color: var(--base-subtitle_color);
  width: 100%;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--base-subtitle_underline_color);
  font-family: var(--custom-font-family1), Sans-Serif;
  color: var(--base-menu_item_title_color);
  border-bottom-color: var(--base-menu_item_title_underline_color);
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: auto;
}
.menu_item_box .menu_item_box_body .menu_item_box_text {
  color: #898989;
}
.menu_item_box .menu_item_box_link {
  position: absolute;
  bottom: 10px;
  text-align: right;
  right: 10px;
  line-height: 26px;
}
.menu_item_box .menu_item_box_link .arrow {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
  color: #2691c5;
}
.menu_item_box.locked {
  cursor: default;
}
.menu_item_box.locked .menu_item_box_wrapper,
.menu_item_box.locked .menu_item_box_link {
  filter: blur(2px);
}
.menu_item_box.locked .locked-menu-item {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  background-color: rgba(39, 145, 197, 0.7);
  color: white;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}
.menu_item_box.locked .locked-menu-item .padlock {
  font-size: 30px;
  float: left;
  margin-right: 10px;
}
.menu_item_box.locked .locked-menu-item .locked-info {
  margin: 0;
  margin-left: 30px;
  display: block;
}

nav.menu-asignaturas ul > li.inactive {
  background-color: white;
}
nav.menu-asignaturas ul > li.inactive > a:hover {
  background-color: white;
}

nav.navbar ul.nav > li > a {
  color: #5e5e5e;
}
nav.navbar ul.nav > li > a:hover {
  background-color: rgba(94, 94, 94, 0.1);
  color: #5e5e5e;
}

:root {
  --background-color-profile1: #ececec;
  --background-color-navbar-profile1: #feda3f;
  --font-color-navbar-profile1: #5e5e5e;
  --font-color-hover-navbar-profile1: #444444;
  --responsive-toggle-button-background-color: #fdb55e;
  --responsive-toggle-button-font-color: #333333;
  --color-omitidas: #c0c0c0;
  --color-correctas: #9ec74d;
  --color-incorrectas: #ea6767;
  --palette-colors-primary: #2691c5;
  --palette-colors-primary-h: 199.6226415094deg;
  --palette-colors-primary-s: 67.6595744681%;
  --palette-colors-primary-l: 46.0784313725%;
  --palette-colors-primary-r: 38;
  --palette-colors-primary-g: 145;
  --palette-colors-primary-b: 197;
  --palette-colors-primario: #2691c5;
  --palette-colors-primario-h: 199.6226415094deg;
  --palette-colors-primario-s: 67.6595744681%;
  --palette-colors-primario-l: 46.0784313725%;
  --palette-colors-primario-r: 38;
  --palette-colors-primario-g: 145;
  --palette-colors-primario-b: 197;
  --palette-colors-background: #fafafa;
  --palette-colors-background-h: 0deg;
  --palette-colors-background-s: 0%;
  --palette-colors-background-l: 98.0392156863%;
  --palette-colors-background-r: 250;
  --palette-colors-background-g: 250;
  --palette-colors-background-b: 250;
  --palette-colors-fontColor: #5b5b5f;
  --palette-colors-fontColor-h: 240deg;
  --palette-colors-fontColor-s: 2.1505376344%;
  --palette-colors-fontColor-l: 36.4705882353%;
  --palette-colors-fontColor-r: 91;
  --palette-colors-fontColor-g: 91;
  --palette-colors-fontColor-b: 95;
  --palette-colors-noticias: #7faf5b;
  --palette-colors-noticias-h: 94.2857142857deg;
  --palette-colors-noticias-s: 34.4262295082%;
  --palette-colors-noticias-l: 52.1568627451%;
  --palette-colors-noticias-r: 127;
  --palette-colors-noticias-g: 175;
  --palette-colors-noticias-b: 91;
  --palette-colors-establecimientos: #309fb2;
  --palette-colors-establecimientos-h: 188.7692307692deg;
  --palette-colors-establecimientos-s: 57.5221238938%;
  --palette-colors-establecimientos-l: 44.3137254902%;
  --palette-colors-establecimientos-r: 48;
  --palette-colors-establecimientos-g: 159;
  --palette-colors-establecimientos-b: 178;
  --cui-showcasebox-colors-primary: #353535;
  --cui-showcasebox-colors-primary-h: 0deg;
  --cui-showcasebox-colors-primary-s: 0%;
  --cui-showcasebox-colors-primary-l: 20.7843137255%;
  --cui-showcasebox-colors-primary-r: 53;
  --cui-showcasebox-colors-primary-g: 53;
  --cui-showcasebox-colors-primary-b: 53;
  --cui-showcasebox-colors-noticias: #7faf5b;
  --cui-showcasebox-colors-noticias-h: 94.2857142857deg;
  --cui-showcasebox-colors-noticias-s: 34.4262295082%;
  --cui-showcasebox-colors-noticias-l: 52.1568627451%;
  --cui-showcasebox-colors-noticias-r: 127;
  --cui-showcasebox-colors-noticias-g: 175;
  --cui-showcasebox-colors-noticias-b: 91;
  --cui-showcasebox-colors-establecimientos: #2ea1ac;
  --cui-showcasebox-colors-establecimientos-h: 185.2380952381deg;
  --cui-showcasebox-colors-establecimientos-s: 57.7981651376%;
  --cui-showcasebox-colors-establecimientos-l: 42.7450980392%;
  --cui-showcasebox-colors-establecimientos-r: 46;
  --cui-showcasebox-colors-establecimientos-g: 161;
  --cui-showcasebox-colors-establecimientos-b: 172;
  --footer-push-height: 320px;
  --custom-font-family3: Sans serif;
  --custom-font-family4: Sans serif;
  --base-landing_logo_max_width: 400px;
  --base-landing_logo_footer_height: 40px;
  --base-landing_login_align: center;
  --background-color-profile1-h: 0deg;
  --background-color-profile1-s: 0%;
  --background-color-profile1-l: 92.5490196078%;
  --background-color-profile1-r: 236;
  --background-color-profile1-g: 236;
  --background-color-profile1-b: 236;
  --background-color-navbar-profile1-h: 48.6910994764deg;
  --background-color-navbar-profile1-s: 98.9637305699%;
  --background-color-navbar-profile1-l: 62.1568627451%;
  --background-color-navbar-profile1-r: 254;
  --background-color-navbar-profile1-g: 218;
  --background-color-navbar-profile1-b: 63;
  --font-color-navbar-profile1-h: 0deg;
  --font-color-navbar-profile1-s: 0%;
  --font-color-navbar-profile1-l: 36.862745098%;
  --font-color-navbar-profile1-r: 94;
  --font-color-navbar-profile1-g: 94;
  --font-color-navbar-profile1-b: 94;
  --font-color-hover-navbar-profile1-h: 0deg;
  --font-color-hover-navbar-profile1-s: 0%;
  --font-color-hover-navbar-profile1-l: 26.6666666667%;
  --font-color-hover-navbar-profile1-r: 68;
  --font-color-hover-navbar-profile1-g: 68;
  --font-color-hover-navbar-profile1-b: 68;
  --responsive-toggle-button-background-color-h: 32.8301886792deg;
  --responsive-toggle-button-background-color-s: 97.5460122699%;
  --responsive-toggle-button-background-color-l: 68.0392156863%;
  --responsive-toggle-button-background-color-r: 253;
  --responsive-toggle-button-background-color-g: 181;
  --responsive-toggle-button-background-color-b: 94;
  --responsive-toggle-button-font-color-h: 0deg;
  --responsive-toggle-button-font-color-s: 0%;
  --responsive-toggle-button-font-color-l: 20%;
  --responsive-toggle-button-font-color-r: 51;
  --responsive-toggle-button-font-color-g: 51;
  --responsive-toggle-button-font-color-b: 51;
  --color-omitidas-h: 0deg;
  --color-omitidas-s: 0%;
  --color-omitidas-l: 75.2941176471%;
  --color-omitidas-r: 192;
  --color-omitidas-g: 192;
  --color-omitidas-b: 192;
  --color-correctas-h: 80.1639344262deg;
  --color-correctas-s: 52.1367521368%;
  --color-correctas-l: 54.1176470588%;
  --color-correctas-r: 158;
  --color-correctas-g: 199;
  --color-correctas-b: 77;
  --color-incorrectas-h: 0deg;
  --color-incorrectas-s: 75.7225433526%;
  --color-incorrectas-l: 66.0784313725%;
  --color-incorrectas-r: 234;
  --color-incorrectas-g: 103;
  --color-incorrectas-b: 103;
}

/* ##############################################

Código loader Spinner fuera de la aplicación

############################################## */
.outside-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}

.spinner {
  width: 50px;
  height: 50px;
  border-top: 6px solid rgba(169, 169, 169, 0.2);
  border-right: 6px solid rgba(169, 169, 169, 0.2);
  border-bottom: 6px solid rgba(169, 169, 169, 0.2);
  border-left: 6px solid #a9a9a9;
  border-radius: 50%;
  -webkit-animation: loadOutside 1.1s infinite linear;
  animation: loadOutside 1.1s infinite linear;
}

@-webkit-keyframes loadOutside {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loadOutside {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
html {
  display: block;
  height: 100%;
  font-size: 16px;
}

puntaje {
  display: block;
  height: 100%;
}

body {
  display: block;
  height: 100%;
}

@-webkit-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadingBody {
  0%, 80% {
    opacity: 0;
    background: white;
  }
  100% {
    opacity: 1;
  }
}
@media print {
  .print-hide {
    display: none !important;
  }
  .print-show-block {
    display: block !important;
  }
  .print-page-break-after {
    page-break-after: always;
    display: block;
  }
  .print-page-break-before {
    page-break-before: always;
    display: block;
  }
  .print-page-break-avoid {
    page-break-inside: avoid;
  }
  .print-full-width {
    width: 100% !important;
  }
  .print-force-expand {
    height: auto !important;
    overflow: auto !important;
  }
}
@page {
  size: auto;
  margin: 0.5in 0.5in 0.5in 0.5in;
}
.index-hidden {
  display: none;
}

.using-mouse button:focus,
.using-mouse button:active:focus {
  outline: none;
}